import React from 'react';
import {StaticMap} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import {GeoJsonLayer} from '@deck.gl/layers';

const SEATS_URL = '../api/election-seat-normalized-winner.csv';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZXNjYXRtYXAiLCJhIjoiY2t4eW82bTlsOWVsbDMxbXUybTk4ZnY2aiJ9.YkGPBHUz7bNM6mDCTe0uSQ';

const INITIAL_VIEW_STATE = {
  latitude: 3.983222, 
  longitude: 102.197416,
  zoom: 6,
  maxZoom: 16,
  pitch: 45,
  bearing: 0
};
const NEGERI = ['JOHOR', 'KEDAH', 'KELANTAN', 'MELAKA', 'NEGERI SEMBILAN', 'PAHANG', 'PERAK', 'PERLIS', 'SABAH', 'TERENGGANU', 'SELANGOR', 'WILAYAH', 'PULAU PINANG'];
// const NEGERI = ['KELANTAN']
const COLOR = {
  "AMANAH": [247, 146, 31],
  "BEBAS": [255, 255, 255],
  "IND": [255, 255, 255],
  "BERSATU": [227, 0, 8],
  "BN": [0, 0, 128],
  "UMNO": [0, 0, 128],
  "MCA": [0, 0, 128],
  "MIC": [0, 0, 128], 
  "DAP": [255, 255, 255],
  "GERAKAN": [237, 44, 55],
  "GPS": [18, 18, 18],
  "LDP": [243, 111, 33],
  "PAS": [0, 136, 0],
  "PBB": [255, 255, 255],
  "PBRS": [252, 242, 2], 
  "PBS": [173, 216, 230],
  "PKR": [0, 172, 239],
  "PH": [216, 35, 42],
  "PN": [1, 45, 80],
  "PPP": [255, 255, 255],
  "PRS": [255, 255, 255],
  "SOLIDARITY": [255, 255, 255],
  "SPDP": [255, 255, 255],
  "STAR": [119, 215, 244], 
  "SUPP": [255, 255, 255],
  "UPKO": [171, 205, 255],
  "WARISAN": [164, 229, 252]
}

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';
let seats = [];

function getSeat(row){
  // console.log(row);
  let seat = seats.find(e => {return e.SeatName === row.properties.Parlimen.toUpperCase()});
  // console.log(seat.SeatName + ': ' + seat.Party + ' (' + seat.year + ')');
  return seat;
}

function getTooltip({object}) {
  if (object){
    let seat = getSeat(object);
    return (
      object && {
        html: `\
    <div>${seat.year}: ${seat.SeatName}, ${object.properties.Negeri.toUpperCase()}</div>
    <div>${seat.Party}</div>
    <div>${seat.NameBallot || seat.Name}</div>
    <div>Undi: ${seat.Votes}</div>
    <div>Majority: ${seat.majority}</div>
    `
      }
    );
  }

}

export default function App({mapStyle = MAP_STYLE}) {
  
  require('d3-fetch').csv(SEATS_URL).then(response => {
    seats = response;
  });

  const layers = NEGERI.map(u => "../api/parlimen-" + u.replace(' ', '-').toLowerCase() + '.geojson').map(data => {
    return new GeoJsonLayer({
      id: data,
      data,
      opacity: 1,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: d => {
        let s = getSeat(d);
        return (Number(s.majority)/Number(s.info_EligibleVoters)*100000);
      },
      getLineColor: [255, 255, 255],
      getLineWidth: 10,
      getFillColor: d => COLOR[getSeat(d).Party]
    })
  });  
  // console.log(layers);
  return (
    <DeckGL
      layers={layers}
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      getTooltip={getTooltip}
    >
      <StaticMap 
        reuseMaps 
        mapStyle={mapStyle} 
        preventStyleDiffing={true} 
        mapboxApiAccessToken={MAPBOX_TOKEN} />
    </DeckGL>
  );
};